/**
 * Sendbird Util
 */
var sbu = {

  getUrlVars: function() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  },

  // TODO: 알림권한이 없는 경우 어딘가에 표시
  sbNotifyMe: function() {
    if (window.Notification && Notification.permission === "granted") {
      console.log("Notification is already granted.");
    } else if (window.Notification && Notification.permission !== "denied") {
      Notification.requestPermission()
        .then(function (permission){
          if (Notification.permission !== permission) {
            Notification.permission = permission;
          }

          if (permission === "granted") {
            console.log("Notification is granted.");
          } else {
            console.log("Notification is denied.");
          }
        });
    }
  },

  notifyMessage: function(channel, message) {
    const iconUrl = location.protocol + '//' + location.host + '/img/sb/sendbird-icon-120x120.png';
    if (window.Notification && Notification.permission === "granted") {
      const noti = new Notification("Sendbird | " + channel.url, {
        icon: iconUrl,
        body: message,
        tag: channel.url
      });
      noti.onclick = function(){
        // todo : 채팅 화면 노출
        window.focus();
      }
    }
  },

  isCurrentUser: function(userId) {
    return (this.getUserId() == userId);
  },

  checkUserId: function(userId) {
    if (!userId) {
      userId = this.getUserId();
    } else {
      this.setCookieUserId(userId);
    }

    if (userId.trim().length == 0) {
      userId = this.generateUUID();
    }

    return userId;
  },

  getUserId: function() {
    let name = 'user_id=';
    let ca = document.cookie.split(';');
    for (let i=0 ; i<ca.length ; i++) {
      let c = ca[i];
      if (!c) continue;
      while (c.charAt(0)==' ') c = c.substring(1);
      if (c.indexOf(name) == 0) {
        return c.substring(name.length,c.length);
      }
    }
    return '';
  },

  generateUUID: function() {
    let d = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  },

  setCookieUserId: function(uuid) {
    document.cookie = "user_id=" + uuid + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    return uuid;
  },

  nameInjectionCheck: function(name) {
    try {
      name = name.replace(/</g, '&lt;');
      name = name.replace(/>/g, '&gt;');

      return name;
    } catch(e) {
      return '';
    }
  },


  convertLinkMessage: function(msg) {
    let returnString = '';

    try {
      msg = msg.replace(/</g, '&lt;');
      msg = msg.replace(/>/g, '&gt;');
    } catch(e) {
      msg = '';
    }
    // dom 도메인 만 추출
    // par 도메인, 파라미터, 경로 전부 추출
    const domExp = {
      dom: /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
      par: /(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}([\/a-z0-9-%#?&=\w])+(\.[a-z0-9]{2,4}(\?[\/a-z0-9-%#?&=\w]+)*)*/gi
    };

    const urlexp = new RegExp('(http|ftp|https)://[a-z0-9\-_]+(\.[a-z0-9\-_]+)+([a-z0-9\-\.,@\?^=%&;:/~\+#]*[a-z0-9\-@\?^=%&;/~\+#])?', 'i');
    if (urlexp.test(msg)) {
      let link = '';
      msg.replace(domExp.par, function (n) {
        link = n;
      })
      returnString += '<img src="/img/sb/icon-link.svg" alt="web link" style="margin-right: 6px;"><a href="' + link + '" target="_blank">' + msg + '</a>';
    } else {
      returnString += msg;
    }

    returnString = returnString.replace(/\r\n/ig, '<br>');
    returnString = returnString.replace(/\\n/ig, '<br>');
    returnString = returnString.replace(/\n/ig, '<br>');

    return returnString;
  }
};

